.App {
  text-align: center;
  background-color: #ffffff;
}

::selection {
  background-color: rgba(35, 131, 226, 0.28);
}


/* NavigationBar */

#navigation-bar {
  width: 100%;
  height: 45px;

  background-color: white;

  position: fixed;
  top: 0;

	user-select: none;
  pointer-events: none;
}

#navigation-bar img { height: 45px; }

#navigation-bar #right { float: right; }
#navigation-bar #left { float: left; }

/* Document */

#document {
  width: 42%;
  height: 100vh;

  position: relative;
  top: 140px;
  left: 50%;

  transform: translateX(-50%);
}

#document::after {
  content: "";
  display: block;
  height: 30vh;
}

/* Header */

#page-header {
  width: 100%;
  height: 100px;
}

#page-header::before {
  position: absolute;
  width: 110%;
  height: 120%;
  transform: translate(-10%, -40%);
  background-color: rgba(0, 0, 0, 0.163);
}

#page-icon {
  width: 78px;
  float: left;
  border-radius: 3px;

  cursor: pointer;
	user-select: none;
  user-drag: none; -webkit-user-drag: none;
}

#page-icon:hover { background-color: rgba(0, 0, 0, 0.062); }

#header-buttons {
  position: absolute;
  left: -3px;
  top: 90px;

  cursor: pointer;
	user-select: none;
  user-drag: none; -webkit-user-drag: none;
}

#header-button-1 { width: 100px; }
#header-button-2 { width: 130px; }

#header-buttons img { border-radius: 5px;  }
#header-buttons img:hover { background-color: rgba(0, 0, 0, 0.062); }


.block {
  position: relative;
  display: inline-block;
  width: 100%; 
  top: 20px;
  margin-bottom: 14px;

}

.hover-line::after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 100%;
  border-bottom: 4px solid #A9CBF5;
}

/* Text */

.block textarea {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;

  border: none;
  outline: none;
  background: transparent;
  resize: none;

  color: black;

  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
                Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  overflow-y: hidden;
  vertical-align: top;
}

.block textarea::placeholder { color: #b4b4b4; }

/* Image */
.image-container {
  position: relative;
  display: inline-block;
}

.imagebox {
  position: relative;
  max-height: 400px;
  max-width: 100%; 

  cursor: pointer;
	user-select: none;
  user-drag: none; -webkit-user-drag: none;
}

.resizer-container {
  position: absolute;
  display: none;
  top: 0;
  height: 100%;
  width: 15px;
  cursor: col-resize;
}

.image-container:hover .resizer-container { display: block; }

.image-resizer {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;

  border-radius: 20px;
  background: rgba(15, 15, 15, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  width: 6px;
  height: 48px;
  max-height: 50%;

  right: 4.5px;
}

.image-buttons {
  position: absolute;
  top: 0;
  right: 0;
  height: 26px;
  opacity: 0.8;
  display: none;
  margin: 4.5px;

  cursor: pointer;
	user-select: none;
  user-drag: none; -webkit-user-drag: none;
}

.image-container:hover .image-buttons { display: block; }

/* Callout */

.callout {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 58px;

  border: 1px solid rgba(55, 53, 47, 0.16);
  border-radius: 3px;

  cursor: text;
}

.callout img {
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translate(-50%, -50%);
  height: 21.312px;
  width: 21.312px;

  border-radius: 3px;
  cursor: pointer;

	user-select: none;
  user-drag: none; -webkit-user-drag: none;
}

.callout img:hover { background-color: rgba(0, 0, 0, 0.062); }

.callout a {
  position: absolute;
  top: 50%;
  left: 48px;
  transform: translateY(-50%);

  cursor: pointer;

  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
                Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  color: black;
  opacity: 0.4;
  text-decoration: none;
  border-bottom: 0.01em solid black;
}

/* BlockSideHandle */

.block-side-handle {
  position: absolute;
  left: -45px;
  height: 100%;
  transform: translateY(-50%);
	user-select: none;
}

.block-side-handle img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  height: 14px;
  opacity: 30%;

  margin: 1px;
  padding: 4px;
  border-radius: 3px;
  cursor: grab;
  user-drag: none; -webkit-user-drag: none;

  z-index: 2;
}

.block-side-handle img:hover { background-color: rgba(0, 0, 0, 0.168); }