
.qrwindow {
	position: absolute;

	width: 300px;
	height: 350px;
	left: 0;

	background: white;
	border-radius: 4px;
	box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px;

	z-index: 100;
}

.qrwindow-header {
	position: relative;

	width: 80%;
	height: 30px;
	left: 10%;
	top: 20px;

	font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
	color: rgba(55, 53, 47, 0.65);
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	user-select: none;
}

.qrwindow-header span {
	color: black;
	font-weight: 600;
}

.qrwindow-img {
	position: relative;
	display: none;

	width: 65%;
	left: 0;
	top: 50px;
	padding: 20px;

	user-select: none;
	pointer-events: none;

	border: none;
	box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px inset;
	border-radius: 10px;

	background-color: #F7F7F5;

}

.qrwindow-img[src*="data:image"] { display: inline-block; }