
.menu {
	position: absolute;

	width: 324px;
	height: 350px;
	left: 0;

	background: white;
	border-radius: 4px;
	box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px;

	z-index: 100;
	overflow: scroll;
}

.menu-section {
	display: block;
	position: relative;

	width: 100%;
	height: 30px;

	transform: translateY(15px);
	padding-left: 14px;

	font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, 
			"Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
	color: rgba(55, 53, 47, 0.65);
	text-transform: uppercase;
	text-align: left;
	font-size: 11px;
	font-weight: 500;
	user-select: none;
}

.menu-item {
	width: calc(100% - 8px);
	height: 60px;

	border-radius: 3px;
	margin: 4px;

	cursor: pointer;
	user-select: none;
}

.menu-item img {
	display: block;
	position: relative;
	float: left;
	top: 0;

	transform: translateY(7px);
	width: 46px;
	height: 46px;

	border-radius: 3px;
	box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px;
	background-color: white;

	margin-right: 4px;
	margin-left: 13px;
}

.menu-item:hover { background-color: rgba(0, 0, 0, 0.062); }

.menu-item-text {
	display: block;
	position: relative;

	transform: translateY(11px);
	padding-left: 7px;

	font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
			Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
	white-space: nowrap;
	text-align: left;

	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 120%;
	user-select: none;
}

.menu-item-name {
	font-size: 14px;
}

.menu-item-description {
	font-size: 12px;
	color: rgba(55, 53, 47, 0.65);
}